body {
    font-family: sans-serif !important;
    font-feature-settings: "palt";
    line-height: 1.71429;
    margin-top: 80px !important;
    padding: 1rem;
}

.header_style {
    background-color: rgb(255, 255, 255) !important;
    opacity: .7;
    filter: drop-shadow(.1rem .1rem .1rem rgba(0, 0, 0, 0.5));
    padding: 0.5rem !important;
    justify-content: center!important;
}

.header_title {
    font-size: 2rem !important;
    font-weight: bold;
    color: rgb(34, 126, 95)!important;
}

.seki {
    display: flex;
    width: 5em;
    height: 5em;
    background-color: rgb(255, 255, 255);
    filter: drop-shadow(.1rem .1rem .1rem rgba(0, 0, 0, 0.5));
    margin: .5em;
    justify-content: center;
    align-items: center;
}

.seki_back {
    display: flex;
    flex-flow: wrap column;
    align-items: center;
    padding: 1em;
    height: 16em;
    background-color: rgb(49, 184, 121);
    white-space:nowrap;
    overflow:scroll;
}